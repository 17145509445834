import * as React from "react";

export const ArrowDownIcon = (props) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <title>Arrow Down</title>
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="1"
        d="M12 2.25a.75.75 0 01.75.75v16.19l6.22-6.22a.75.75 0 111.06 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 111.06-1.06l6.22 6.22V3a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      />
    </svg>
  );
};
